var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      key: _vm.id,
      staticClass: "el-loading--maia",
      attrs: { id: "issuedetail", "element-loading-text": "问题数据获取中..." }
    },
    [
      _vm.readOrModify && _vm.type != "DOC"
        ? _c(
            "div",
            { staticClass: "list" },
            [
              _c(
                "el-form",
                {
                  ref: "readData",
                  attrs: {
                    model: _vm.readData,
                    size: "mini",
                    "label-width": "95px"
                  }
                },
                [
                  _c("el-form-item", { attrs: { label: "问题状态" } }, [
                    _c("div", { staticClass: "queStatus" }, [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.readData.queStatus) + " ")
                      ]),
                      !(
                        _vm.readData.isDel == "1" ||
                        _vm.readData.fileIsDel == "0"
                      )
                        ? _c(
                            "span",
                            [
                              _vm.isNewList
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: { click: _vm.editorQM }
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                              _vm.isNewList
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: { click: _vm.deleteItem }
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ]),
                  _vm.isNewList
                    ? _c("el-form-item", { attrs: { label: "问题截图" } }, [
                        !!_vm.readData.urlList &&
                        _vm.readData.urlList.length > 0
                          ? _c(
                              "div",
                              { staticClass: "MQcut" },
                              _vm._l(_vm.readData.urlList, function(
                                img,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: img, staticClass: "uploadpic" },
                                  [
                                    _c("img", {
                                      attrs: { src: img, alt: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.imgshow(
                                            _vm.readData.urlList,
                                            index
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    : _c("el-form-item", { attrs: { label: "问题截图" } }, [
                        !!_vm.readData.imgList &&
                        _vm.readData.imgList.length > 0
                          ? _c(
                              "div",
                              { staticClass: "MQcut" },
                              _vm._l(_vm.readData.imgList, function(
                                img,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "uploadpic" },
                                  [
                                    _c("img", {
                                      attrs: { src: img.thumbnailUrl, alt: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.imgshow(
                                            _vm.readData.imgList,
                                            index
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]),
                  _c("el-form-item", { attrs: { label: "问题描述" } }, [
                    _c("div", { staticClass: "textArea" }, [
                      _vm._v(" " + _vm._s(_vm.readData.queDesc) + " ")
                    ])
                  ]),
                  _c("el-form-item", { attrs: { label: "专业" } }, [
                    _vm._v(" " + _vm._s(_vm.readData.specName) + " ")
                  ]),
                  _c("el-form-item", { attrs: { label: "跨专业" } }, [
                    _vm._v(" " + _vm._s(_vm.readData.multiSpec) + " ")
                  ]),
                  _vm.type == "3D"
                    ? _c("el-form-item", { attrs: { label: "所属空间" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.readData.roomName || "") +
                                (_vm.readData.roomNumber || "")
                            ) +
                            " "
                        )
                      ])
                    : _vm._e(),
                  _vm.type == "3D"
                    ? _c("el-form-item", { attrs: { label: "所属构件" } }, [
                        _vm._v(" " + _vm._s(_vm.readData.categoryName) + " ")
                      ])
                    : _vm._e(),
                  _c("el-form-item", { attrs: { label: "问题类型" } }, [
                    _vm._v(" " + _vm._s(_vm.readData.queTypeName) + " ")
                  ]),
                  _vm.type == "2D" && _vm.readData.deduction == "C1"
                    ? _c("el-form-item", { attrs: { label: "问题等级" } }, [
                        _vm._v(
                          " " + _vm._s(_vm.readData.queFirstLevelName) + " "
                        )
                      ])
                    : _vm.type == "3D" &&
                      _vm.readData.queSource != "正向图纸" &&
                      _vm.readData.queSource != "模型" &&
                      _vm.readData.deduction == "C1"
                    ? _c("el-form-item", { attrs: { label: "问题等级" } }, [
                        _vm._v(" " + _vm._s(_vm.readData.queLevel) + " ")
                      ])
                    : _vm._e(),
                  _c("el-form-item", { attrs: { label: "修改方" } }, [
                    _vm._v(" " + _vm._s(_vm.readData.modifyPartyName) + " ")
                  ]),
                  _c("el-form-item", { attrs: { label: "要求完成时间" } }, [
                    _vm._v(" " + _vm._s(_vm.readData.requireFinishTime) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        : !_vm.readOrModify && _vm.type != "DOC"
        ? _c(
            "div",
            { staticClass: "list" },
            [
              _c(
                "el-form",
                {
                  key: "modifyData",
                  ref: "modifyData",
                  attrs: {
                    model: _vm.modifyData,
                    rules: _vm.rules,
                    size: "mini",
                    "label-width": "95px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "问题状态", prop: "queStatus" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "el-select--maia",
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.modifyData.queStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.modifyData, "queStatus", $$v)
                            },
                            expression: "modifyData.queStatus"
                          }
                        },
                        _vm._l(_vm.dicArray.statusList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              disabled:
                                (item.id == "22" ||
                                  item.id == "23" ||
                                  item.id == "24") &&
                                (_vm.modifyData.queStatus == "无效" ||
                                  _vm.modifyData.queStatus == "重复"),
                              label: item.cnname,
                              value: item.cnname
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "问题截图" } }, [
                    !!_vm.readData.urlList && _vm.readData.urlList.length > 0
                      ? _c(
                          "div",
                          { staticClass: "MQcut" },
                          _vm._l(_vm.readData.urlList, function(img, index) {
                            return _c(
                              "div",
                              { key: img, staticClass: "uploadpic" },
                              [
                                _c("img", {
                                  attrs: { src: img, alt: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.imgshow(
                                        _vm.readData.urlList,
                                        index
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "问题描述", prop: "queDesc" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 5,
                          autosize: { minRows: 5, maxRows: 6 },
                          maxlength: "200",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.modifyData.queDesc,
                          callback: function($$v) {
                            _vm.$set(_vm.modifyData, "queDesc", $$v)
                          },
                          expression: "modifyData.queDesc"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.type == "2D"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "专业", prop: "specName" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "el-select--maia",
                              attrs: { filterable: "" },
                              on: { change: _vm.specChange },
                              model: {
                                value: _vm.modifyData.specName,
                                callback: function($$v) {
                                  _vm.$set(_vm.modifyData, "specName", $$v)
                                },
                                expression: "modifyData.specName"
                              }
                            },
                            _vm._l(_vm.dicArray.prjSpecList, function(item) {
                              return _c("el-option", {
                                key: item.specId,
                                attrs: {
                                  label: item.specName,
                                  value: item.specName
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm.type == "3D"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "专业", prop: "specName" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "el-select--maia",
                              attrs: { filterable: "" },
                              on: { change: _vm.specChange },
                              model: {
                                value: _vm.modifyData.specName,
                                callback: function($$v) {
                                  _vm.$set(_vm.modifyData, "specName", $$v)
                                },
                                expression: "modifyData.specName"
                              }
                            },
                            _vm._l(_vm.dicArray.specList, function(item) {
                              return _c("el-option", {
                                key: item.specId,
                                attrs: {
                                  label: item.specName,
                                  value: item.specName
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type == "2D"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "跨专业" } },
                        [
                          _c(
                            "el-select",
                            {
                              key: "modifyData.multiSpecId",
                              staticClass: "el-select--maia",
                              attrs: {
                                filterable: "",
                                "collapse-tags": "",
                                multiple: ""
                              },
                              model: {
                                value: _vm.modifyData.multiSpecId,
                                callback: function($$v) {
                                  _vm.$set(_vm.modifyData, "multiSpecId", $$v)
                                },
                                expression: "modifyData.multiSpecId"
                              }
                            },
                            _vm._l(
                              _vm.dicArray.prjSpecList.filter(function(a) {
                                return a.specName != _vm.modifyData.specName
                              }),
                              function(item) {
                                return _c("el-option", {
                                  key: item.specId,
                                  attrs: {
                                    label: item.specName,
                                    value: item.specId
                                  }
                                })
                              }
                            ),
                            1
                          )
                        ],
                        1
                      )
                    : _vm.type == "3D"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "跨专业" } },
                        [
                          _c(
                            "el-select",
                            {
                              key: "modifyData.multiSpecId",
                              staticClass: "el-select--maia",
                              attrs: {
                                filterable: "",
                                "collapse-tags": "",
                                multiple: ""
                              },
                              model: {
                                value: _vm.modifyData.multiSpecId,
                                callback: function($$v) {
                                  _vm.$set(_vm.modifyData, "multiSpecId", $$v)
                                },
                                expression: "modifyData.multiSpecId"
                              }
                            },
                            _vm._l(
                              _vm.dicArray.specList.filter(function(a) {
                                return a.specName != _vm.modifyData.specName
                              }),
                              function(item) {
                                return _c("el-option", {
                                  key: item.specId,
                                  attrs: {
                                    label: item.specName,
                                    value: item.specId
                                  }
                                })
                              }
                            ),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  false
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "航道", prop: "deduction" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                disabled:
                                  _vm.readData.deduction == "C1" ||
                                  _vm.readData.deduction == "C2"
                              },
                              model: {
                                value: _vm.modifyData.deduction,
                                callback: function($$v) {
                                  _vm.$set(_vm.modifyData, "deduction", $$v)
                                },
                                expression: "modifyData.deduction"
                              }
                            },
                            _vm._l(_vm.hangDaoSelect, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type == "3D" && _vm.readData.queSource == "非正向图纸"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "航道", prop: "deduction" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                disabled:
                                  _vm.readData.deduction == "C1" ||
                                  _vm.readData.deduction == "C2"
                              },
                              model: {
                                value: _vm.modifyData.deduction,
                                callback: function($$v) {
                                  _vm.$set(_vm.modifyData, "deduction", $$v)
                                },
                                expression: "modifyData.deduction"
                              }
                            },
                            _vm._l(_vm.hangDaoSelect, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type == "3D"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "所属空间", prop: "deduction" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                value:
                                  (_vm.readData.roomName || "") +
                                  (_vm.readData.roomNumber || ""),
                                filterable: "",
                                disabled: true
                              }
                            },
                            _vm._l([], function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type == "3D"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "所属构件", prop: "deduction" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "", disabled: true },
                              model: {
                                value: _vm.readData.categoryName,
                                callback: function($$v) {
                                  _vm.$set(_vm.readData, "categoryName", $$v)
                                },
                                expression: "readData.categoryName"
                              }
                            },
                            _vm._l([], function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item, value: item }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type == "2D"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "问题类型", prop: "queTypeName" } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c(
                                "span",
                                { staticClass: "span-box" },
                                [
                                  _c("span", [_vm._v("问题类型")]),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right-end",
                                        width: "279",
                                        trigger: "click",
                                        "popper-class": "popType"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning iconPrompt",
                                        attrs: { slot: "reference" },
                                        on: { click: _vm.openTypeList },
                                        slot: "reference"
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "popContent" },
                                        [
                                          _c("p", { staticClass: "title" }, [
                                            _vm._v("问题类型")
                                          ]),
                                          _vm.modifyData.deduction == "C1"
                                            ? _c(
                                                "el-table",
                                                {
                                                  attrs: {
                                                    data: _vm.issueTypeDataC1,
                                                    stripe: "",
                                                    "header-cell-style":
                                                      _vm.headClass,
                                                    "span-method":
                                                      _vm.spanMethod,
                                                    size: "medium"
                                                  }
                                                },
                                                [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      property: "name",
                                                      label: "问题类型"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      property: "namescr",
                                                      label: "问题类型描述"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm.modifyData.deduction == "C2"
                                            ? _c(
                                                "el-table",
                                                {
                                                  attrs: {
                                                    data: _vm.issueTypeDataC2,
                                                    stripe: "",
                                                    "header-cell-style":
                                                      _vm.headClass,
                                                    "span-method":
                                                      _vm.spanMethod,
                                                    size: "medium"
                                                  }
                                                },
                                                [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      property: "name",
                                                      label: "问题类型",
                                                      width: "100"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      property: "namescr",
                                                      label: "问题类型描述"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(scope) {
                                                            return _vm._l(
                                                              scope.row.namescr.split(
                                                                "\n"
                                                              ),
                                                              function(
                                                                item,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "p",
                                                                  {
                                                                    key: index
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1814534925
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm.readData.deduction == "C1"
                            ? _c(
                                "el-select",
                                {
                                  attrs: { filterable: "" },
                                  model: {
                                    value: _vm.modifyData.queTypeName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.modifyData,
                                        "queTypeName",
                                        $$v
                                      )
                                    },
                                    expression: "modifyData.queTypeName"
                                  }
                                },
                                _vm._l(_vm.dicArray.typeList, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.cnname,
                                      value: item.cnname
                                    }
                                  })
                                }),
                                1
                              )
                            : _vm.readData.deduction == "C2"
                            ? _c(
                                "el-select",
                                {
                                  attrs: { filterable: "" },
                                  model: {
                                    value: _vm.modifyData.queTypeName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.modifyData,
                                        "queTypeName",
                                        $$v
                                      )
                                    },
                                    expression: "modifyData.queTypeName"
                                  }
                                },
                                _vm._l(
                                  _vm.dicArray.c2QuestionTypeList,
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.cnname,
                                        value: item.cnname
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm.type == "3D"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "问题类型", prop: "queTypeName" } },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c(
                                "span",
                                { staticClass: "span-box" },
                                [
                                  _c("span", [_vm._v("问题类型")]),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right-end",
                                        width: "279",
                                        trigger: "click",
                                        "popper-class": "popType"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning iconPrompt",
                                        attrs: { slot: "reference" },
                                        on: { click: _vm.openTypeList },
                                        slot: "reference"
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "popContent" },
                                        [
                                          _c("p", { staticClass: "title" }, [
                                            _vm._v("问题类型")
                                          ]),
                                          _vm.readData.deduction == "C1"
                                            ? _c(
                                                "el-table",
                                                {
                                                  attrs: {
                                                    data: _vm.issueTypeDataC1,
                                                    stripe: "",
                                                    "header-cell-style":
                                                      _vm.headClass,
                                                    "span-method":
                                                      _vm.spanMethod,
                                                    size: "medium"
                                                  }
                                                },
                                                [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      property: "name",
                                                      label: "问题类型"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      property: "namescr",
                                                      label: "问题类型描述"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm.readData.deduction == "C2"
                                            ? _c(
                                                "el-table",
                                                {
                                                  attrs: {
                                                    data: _vm.issueTypeDataC2,
                                                    stripe: "",
                                                    "header-cell-style":
                                                      _vm.headClass,
                                                    "span-method":
                                                      _vm.spanMethod,
                                                    size: "medium"
                                                  }
                                                },
                                                [
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      property: "name",
                                                      label: "问题类型",
                                                      width: "100"
                                                    }
                                                  }),
                                                  _c("el-table-column", {
                                                    attrs: {
                                                      property: "namescr",
                                                      label: "问题类型描述"
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "default",
                                                        fn: function(scope) {
                                                          return _vm._l(
                                                            scope.row.namescr.split(
                                                              "\n"
                                                            ),
                                                            function(
                                                              item,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "p",
                                                                { key: index },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        }
                                                      }
                                                    ])
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm.readData.queSource != "非正向图纸"
                            ? _c(
                                "el-select",
                                {
                                  attrs: { filterable: "" },
                                  model: {
                                    value: _vm.modifyData.queTypeName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.modifyData,
                                        "queTypeName",
                                        $$v
                                      )
                                    },
                                    expression: "modifyData.queTypeName"
                                  }
                                },
                                _vm._l(
                                  _vm.dicArray.typeList.filter(function(item) {
                                    return !item.cnname.endsWith("关注点")
                                  }),
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.cnname,
                                        value: item.cnname
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm.prjBuId == "X1" &&
                              _vm.readData.deduction == "C1"
                            ? _c(
                                "el-select",
                                {
                                  attrs: { filterable: "" },
                                  model: {
                                    value: _vm.modifyData.queTypeName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.modifyData,
                                        "queTypeName",
                                        $$v
                                      )
                                    },
                                    expression: "modifyData.queTypeName"
                                  }
                                },
                                _vm._l(
                                  _vm.dicArray.dwgQueTypeList.filter(function(
                                    item
                                  ) {
                                    return item.channel == "c1"
                                  }),
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.cnname,
                                        value: item.cnname
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm.prjBuId == "X1" &&
                              _vm.readData.deduction == "C2"
                            ? _c(
                                "el-select",
                                {
                                  attrs: { filterable: "" },
                                  model: {
                                    value: _vm.modifyData.queTypeName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.modifyData,
                                        "queTypeName",
                                        $$v
                                      )
                                    },
                                    expression: "modifyData.queTypeName"
                                  }
                                },
                                _vm._l(
                                  _vm.dicArray.dwgQueTypeList.filter(function(
                                    item
                                  ) {
                                    return item.channel == "c2"
                                  }),
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.cnname,
                                        value: item.cnname
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            : _c(
                                "el-select",
                                {
                                  attrs: { filterable: "" },
                                  model: {
                                    value: _vm.modifyData.queTypeName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.modifyData,
                                        "queTypeName",
                                        $$v
                                      )
                                    },
                                    expression: "modifyData.queTypeName"
                                  }
                                },
                                [
                                  _c(
                                    "el-option",
                                    { attrs: { label: "123", value: "" } },
                                    [_vm._v("11")]
                                  ),
                                  _vm._l(_vm.dicArray.dwgQueTypeList, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.cnname,
                                        value: item.cnname
                                      }
                                    })
                                  })
                                ],
                                2
                              )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.readData.queSource != "正向图纸" &&
                  _vm.readData.queSource != "模型" &&
                  _vm.readData.deduction == "C1"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "问题等级",
                            prop: "queFirstLevelName"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _c(
                                "span",
                                { staticClass: "span-box" },
                                [
                                  _c("span", [_vm._v("问题等级")]),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right-end",
                                        width: "279",
                                        trigger: "click",
                                        "popper-class": "popType"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-warning iconPrompt",
                                        attrs: { slot: "reference" },
                                        on: { click: _vm.openLevelList },
                                        slot: "reference"
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "popContent" },
                                        [
                                          _c("p", { staticClass: "title" }, [
                                            _vm._v("问题等级")
                                          ]),
                                          _c(
                                            "el-table",
                                            {
                                              attrs: {
                                                data: _vm.levelData,
                                                stripe: "",
                                                "header-cell-style":
                                                  _vm.headClass,
                                                "span-method":
                                                  _vm.spanMethodlevel,
                                                size: "medium"
                                              }
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  property: "name",
                                                  label: "问题等级",
                                                  width: "70"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  property: "namescr",
                                                  label: "问题等级描述"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "" },
                              model: {
                                value: _vm.modifyData.queFirstLevelName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.modifyData,
                                    "queFirstLevelName",
                                    $$v
                                  )
                                },
                                expression: "modifyData.queFirstLevelName"
                              }
                            },
                            _vm._l(_vm.dicArray.queLevelList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.cnname,
                                  value: item.cnname
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "修改方" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", filterable: "" },
                          model: {
                            value: _vm.modifyData.modifyPartyName,
                            callback: function($$v) {
                              _vm.$set(_vm.modifyData, "modifyPartyName", $$v)
                            },
                            expression: "modifyData.modifyPartyName"
                          }
                        },
                        _vm._l(_vm.dicArray.modifyPartyList, function(item) {
                          return _c("el-option", {
                            key: item.depID,
                            attrs: { label: item.depName, value: item.depName }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "要求完成时间",
                        prop: "requireFinishTime"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "picker-options": _vm.pickerOptions,
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                          type: "date"
                        },
                        model: {
                          value: _vm.modifyData.requireFinishTime,
                          callback: function($$v) {
                            _vm.$set(_vm.modifyData, "requireFinishTime", $$v)
                          },
                          expression: "modifyData.requireFinishTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "foot" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "maiaPlain", size: "small" },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "18px" },
                      attrs: { type: "maiaPrimary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.sure("modifyData")
                        }
                      }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "list" },
            [
              _c(
                "el-form",
                {
                  ref: "readData",
                  attrs: {
                    model: _vm.readData,
                    size: "mini",
                    "label-width": "95px"
                  }
                },
                [
                  _c("el-form-item", { attrs: { label: "问题状态" } }, [
                    _c("div", { staticClass: "queStatus" }, [
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.readData.status) + " ")
                      ]),
                      _c("span")
                    ])
                  ]),
                  _vm.isNewList
                    ? _c("el-form-item", { attrs: { label: "问题截图" } }, [
                        !!_vm.readData.urlList &&
                        _vm.readData.urlList.length > 0
                          ? _c(
                              "div",
                              { staticClass: "MQcut" },
                              _vm._l(_vm.readData.urlList, function(
                                img,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "uploadpic" },
                                  [
                                    _c("img", {
                                      attrs: { src: img, alt: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.imgshow(
                                            _vm.readData.urlList,
                                            index
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ])
                    : _c("el-form-item", { attrs: { label: "问题截图" } }, [
                        !!_vm.readData.imgList &&
                        _vm.readData.imgList.length > 0
                          ? _c(
                              "div",
                              { staticClass: "MQcut" },
                              _vm._l(_vm.readData.imgList, function(
                                img,
                                index
                              ) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "uploadpic" },
                                  [
                                    _c("img", {
                                      attrs: { src: img.thumbnailUrl, alt: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.imgshow(
                                            _vm.readData.imgList,
                                            index
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]),
                  _c("el-form-item", { attrs: { label: "问题描述" } }, [
                    _c("div", { staticClass: "textArea" }, [
                      _vm._v(" " + _vm._s(_vm.readData.remark) + " ")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }